import numeral from 'numeral'

export const numberMixins = {
  methods: {
    mxNumeralThousandSeperate(n, decimal = 2) {
      if (decimal <= 0) {
        return numeral(n).format(`0,0`)
      } else {
        let decimalDigits = ''
        for (let i = 1; i <= decimal; i++) decimalDigits += '0'
        return numeral(n).format(`0,0.${decimalDigits}`)
      }
    },
    mxGetBahtText(numberAmount) {
      let [integerValue, fractionValue] = numeral(Math.abs(numberAmount))
        .format('0.00')
        .split('.')

      let convert = stringNumber => {
        let values = [
          '',
          'หนึ่ง',
          'สอง',
          'สาม',
          'สี่',
          'ห้า',
          'หก',
          'เจ็ด',
          'แปด',
          'เก้า'
        ]
        let places = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน']
        let exceptions = {
          หนึ่งสิบ: 'สิบ',
          สองสิบ: 'ยี่สิบ',
          สิบหนึ่ง: 'สิบเอ็ด'
        }

        let output = ''

        stringNumber
          .split('')
          .reverse()
          .forEach((el, index) => {
            if (index % 6 === 0 && index > 0) {
              output = places[6] + output
            }

            if (el !== '0') {
              output = values[el] + places[index % 6] + output
            }
          })

        Object.keys(exceptions).forEach(key => {
          const value = exceptions[key]
          output = output.replace(key, value)
        })

        return output
      }

      let baht = convert(integerValue)
      let satang = convert(fractionValue)

      let output = numberAmount < 0 ? 'ลบ' : ''
      output += baht ? `${baht}บาท` : ''
      output += satang ? `${satang}สตางค์` : 'ถ้วน'

      return `${baht}${satang}` === '' ? 'ศูนย์บาทถ้วน' : output
    }
  }
}
