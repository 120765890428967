import { isNil } from 'lodash'
import moment from 'moment'

export const datetimeMixins = {
  methods: {
    mxConvertToDatetimeBuddhistFormat(dateISOString) {
      if (isNil(dateISOString)) {
        return '-'
      }

      const dateISOStringTh =
        moment(dateISOString)
          .utcOffset('+07:00')
          .toISOString(true)
          .slice(0, 23) + 'Z'

      const datetimeArrays = dateISOStringTh.split('T')
      const dateArrays = datetimeArrays[0].split('-')
      const timeArrays = datetimeArrays[1].split(':')

      const datePart = `${dateArrays[2]}/${dateArrays[1]}/${+dateArrays[0] +
        543}`
      const timePart = `${timeArrays[0]}:${timeArrays[1]}`

      return `${datePart} (${timePart})`
    },
    mxConvertToDateBuddhistFormat(dateISOString) {
      if (isNil(dateISOString)) {
        return '-'
      }

      const dateISOStringTh =
        moment(dateISOString)
          .utcOffset('+07:00')
          .toISOString(true)
          .slice(0, 23) + 'Z'

      const datetimeArrays = dateISOStringTh.split('T')
      const dateArrays = datetimeArrays[0].split('-')

      const datePart = `${dateArrays[2]}/${dateArrays[1]}/${+dateArrays[0] +
        543}`

      return `${datePart}`
    }
  }
}
