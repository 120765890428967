export const inputFieldMixins = {
  methods: {
    mxFocusNextInputField() {
      var inputs = document.getElementsByTagName('input')
      for (var i = 0; i < inputs.length; i++) {
        const checkCurrentElement = document.activeElement.id == inputs[i].id
        const checkElementIndex = i + 1 < inputs.length
        if (checkCurrentElement && checkElementIndex) {
          inputs[i + 1].focus()
          break
        }
      }
    }
  }
}
